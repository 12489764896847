import React, { useState, useEffect } from 'react';
import { Spin, message as notify } from 'antd';
import Amplify from 'aws-amplify';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import awsconfig from '../backendConfigProvider';
import * as signupStyles from '../styles/auth/Signup.module.scss';
import * as styles from '../styles/auth/Redeem.module.scss';
import { amplifySignUp } from '../services/SignupService';
import SignupHeader from '../components/Auth/Shared/SignupHeader';
import Footer from '../components/Shared/Footer';
import SignupOtpView from '../components/Auth/Shared/SignupOtpView';
import { getUserCountry } from '../services/utils';
import RedeemPersonalInfo from '../components/Auth/Redeem/RedeemPersonalInfo';
import {
  FormData, Payload, SignupView, UserCountry,
} from './signup';

Amplify.configure(awsconfig);

const defaultFormData = {
  firstName: '',
  lastName: '',
  password: '',
  email: '',
  phoneNumber: '',
  terms: false,
  promotions: false,
  voucher: '',
};

const Redeem: React.FC = () => {
  const [formData, setFormData] = useState<FormData>(defaultFormData);
  const [signupView, setSignupView] = useState<SignupView>(SignupView.LOADING);
  const [accountExists, setAccountExists] = useState(false);
  const [userCountry, setUserCountry] = useState<UserCountry>({
    countryCode: '',
    fetchedFromIpInfo: false,
  });
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    password: false,
    email: false,
    phoneNumber: false,
  });

  const createPayload: () => Payload & {
    voucher: string
  } = () => {
    const fullName = `${formData.firstName} ${formData.lastName}`;
    const payload = {
      name: fullName,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      password: formData.password,
      promotions: formData.promotions,
      phoneNumber: formData.phoneNumber,
      voucher: formData.voucher || '',
    };
    return payload;
  };

  const signupHandler = async (payload: Payload, throughSignupFlow: boolean | undefined) => {
    await amplifySignUp(payload)
      .then(async ({ error }: {error: any }) => {
        if (error) {
          if (error.code === 'UsernameExistsException') {
            setSignupView(SignupView.PROFILE_DETAILS_VIEW);
            setAccountExists(true);
            notify.error('Account already exists', 5);
          } else if (error.message.includes('ERROR_CODE:DOMAIN_NOT_ALLOWED')) {
            setSignupView(SignupView.PROFILE_DETAILS_VIEW);
            setFormData(defaultFormData);
            notify.error('Company email required.', 5);
          } else {
            setSignupView(SignupView.PROFILE_DETAILS_VIEW);
            setFormData(defaultFormData);
            notify.error(error.message, 5);
          }
        } else {
          if (throughSignupFlow) {
            window.dataLayer.push({
              event: 'Lead',
              formId: 'self-starter-plan',
            });
          }
          setSignupView(SignupView.OTP_VIEW);
        }
      }).catch((err: any) => {
        setFormData(defaultFormData);
        setSignupView(SignupView.PROFILE_DETAILS_VIEW);
        notify.error(err.message, 5);
      });
  };

  const getOtpHandler = (
    e:React.FormEvent<HTMLFormElement>,
    throughSignupFlow: boolean | undefined,
  ) => {
    e.preventDefault();
    setSignupView(SignupView.LOADING);
    const payload = createPayload();
    try {
      signupHandler(payload, throughSignupFlow);
    } catch (error: any) {
      setSignupView(SignupView.PROFILE_DETAILS_VIEW);
      notify.error('Request failed', 5);
    }
  };

  const fetchUserCountry = async () => {
    const countryCode = await getUserCountry();
    if (countryCode) {
      setUserCountry({
        countryCode,
        fetchedFromIpInfo: true,
      });
    }
    setSignupView(SignupView.PROFILE_DETAILS_VIEW);
  };

  useEffect(() => {
    fetchUserCountry();
  }, []);

  return (
    <>
      <Helmet>
        <title>Upskill Your Employees | Upskillist APP Signup</title>
      </Helmet>
      <SignupHeader />
      <div className={signupStyles.signup}>
        <StaticImage
          className={signupStyles.signupBackground}
          src="../../static/images/shared/signup-background.png"
          alt="Dashed lines as background"
        />
        <div className={signupStyles.signupLeft}>
          <div className={signupStyles.signupTitle}>
            <h1 className={signupStyles.titleText}>
              <span className={signupStyles.brandTitle}>Upskill</span>
              {' '}
              your team.
            </h1>
          </div>
          <StaticImage
            className={signupStyles.signupImage}
            src="../../static/images/shared/skill-gap-signup-image.png"
            alt="A group of people staring at a laptop"
          />
          <p className={signupStyles.upskillYourTeam}>
            Start upskilling your team now!
          </p>
          <ul className={signupStyles.benefitsList}>
            <li>Get access to All our courses.</li>
            <li>Invite your team.</li>
            <li>See your performance.</li>
          </ul>
        </div>
        <div
          className={`${styles.redeemContainer} ${(errors.password) && styles.singleRedeemInputError}`}
        >
          <div className={styles.formContainer}>
            {
              signupView === SignupView.LOADING
              && (
              <div className={styles.spinnerContainer}>
                <div className={styles.spinner}>
                  <Spin />
                </div>
              </div>
              )
            }
            {signupView === SignupView.PROFILE_DETAILS_VIEW
              && (
                <RedeemPersonalInfo
                  throughSignupFlow
                  formData={formData}
                  setFormData={setFormData}
                  getOtpHandler={getOtpHandler}
                  accountExists={accountExists}
                  userCountry={userCountry}
                  setUserCountry={setUserCountry}
                  setErrors={setErrors}
                  errors={errors}
                />
              )}
            {
              signupView === SignupView.OTP_VIEW
              && (
                <SignupOtpView
                  formData={formData}
                  setSignupView={setSignupView}
                  userCountry={userCountry}
                  throughSignupFlow
                />
              )
            }
          </div>
          <p className={styles.loginText}>
            Already have an account?
            {' '}
            <button type="button" onClick={() => navigate('/login')}>Login.</button>
          </p>
          <p className={styles.faq}>
            Check our
            {' '}
            <a href="https://support.upskillist.pro/">Business FAQs</a>
            {' '}
            for more information.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Redeem;
